<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event && race">
      <Header :event="event"/>
      <v-card-title class="headline">
        Time Slot Filters for <RaceSelector :event="event" :race="race" routeName="eventmanagerRaceTimeSlots" />
      </v-card-title>

      <v-card-text>      
        Configure day-of-week and/or time-of-day filters for this leaderboard.
      </v-card-text>

      <v-card-text>      
        <p>Note: when at least one time slot is defined, <strong>only activities within this time slot</strong> can qualify for this leaderboard.</p>
        <p>Note 2: for step challenges you can only use day of week filtering. Time of day filtering is not possible for step challenges.</p>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="slots"
        :items-per-page="100"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Allowed Time Slots</v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
        </template>
        <template v-slot:item.from="{ item }" >
          <v-text-field v-model="item.from" hide-details placeholder="hh:mm (24h-format)" />
        </template>
        <template v-slot:item.till="{ item }">
          <v-text-field v-model="item.till" hide-details placeholder="hh:mm (24h-format)" />
        </template>
        <template v-slot:item.days="{ item }">
          <v-checkbox v-model="item.days" multiple hide-details dense class="checkbox-in-row" value="Monday" label="Mo" />
          <v-checkbox v-model="item.days" multiple hide-details dense class="checkbox-in-row" value="Tuesday" label="Tu" />
          <v-checkbox v-model="item.days" multiple hide-details dense class="checkbox-in-row" value="Wednesday" label="We" />
          <v-checkbox v-model="item.days" multiple hide-details dense class="checkbox-in-row" value="Thursday" label="Th" />
          <v-checkbox v-model="item.days" multiple hide-details dense class="checkbox-in-row" value="Friday" label="Fr" />
          <v-checkbox v-model="item.days" multiple hide-details dense class="checkbox-in-row" value="Saturday" label="Sa" />
          <v-checkbox v-model="item.days" multiple hide-details dense class="checkbox-in-row" value="Sunday" label="Su" />
        </template>
        <template v-slot:footer>
          <v-btn text @click="addRow"><v-icon small color="gray" class="mr-2">fa-plus-circle</v-icon> Add a filter</v-btn>
          <span class="text-muted">(To delete a row: clear the data and press Save)</span>
        </template>
      </v-data-table>

      <v-card-text>
        <v-btn color="primary" large @click="save">Save</v-btn>
      </v-card-text>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import RaceSelector from './_RaceSelector.vue'
import DistanceTextArea from "@/components/DistanceTextArea";

export default {
  name: "RaceTiers",
  components: {
    Header,
    RaceSelector,
    DistanceTextArea,
  },
  props: {
  },
  data() {
    return {
      event: null,
      race: null,
      slots: [],
      siteData: siteData,
      headers: [
        { text: 'From (time of day)', value: 'from', sortable: false, class: 'narrowCol' },
        { text: 'Till (time of day)', value: 'till', sortable: false, class: 'narrowCol' },
        { text: 'Days of week (only on selected)', value: 'days', sortable: false },
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id, to.params.raceId);
    next();
  },
  methods: {
    async getEvent(id, raceId) {
      this.event = (await eventManagerService.get(id)).data;
      this.race = this.event.races.find(r => r.id == raceId);
      this.slots = this.race.time_slot_filters || [];
      for (const slot of this.slots) {
        slot.days = slot.days || [];
      }
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    async addRow() {
      this.slots.push({ from: '00:00', till: '23:59'});
    },

    async save() {
      console.log('Saving', this.tiers);
      const response = (await eventManagerService.putRaceTimeSlots(this.event.id, this.race.id, this.slots)).data;
      this.$helpers.toastResponse(this, response, 'Successfully saved time slots.');

      await this.getProfile();
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'eventmanagerRace', params: {id: this.event.id, raceId: this.race.id}} },
        { text: 'Time Slot Filters', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
.v-data-table.v-data-table--dense td { padding-top: 4px !important; padding-bottom:  4px !important;}
.v-input.v-input--hide-details { margin-top: 0 !important; }
.handle {cursor: move;}

.narrowCol { width: 150px;}
.checkbox-in-row { 
  display: inline-block !important;
  margin-right: 20px;

}
</style>

